<template>
  <div class="p-1">
    <div class="flex justify-between flex-wrap mb-2 items-center">
      <h3 class="text-2xl">Pensums</h3>
      <a-button :to="{ name: 'pensums.create' }" v-if="!user.isStudent && !user.isLowAdmin" class="w-full md:w-auto">
        Crear pensum
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      toolbar-column-filter
      :pagination="pagination"
      @paginate="search({ page: $event })"
      @toolbar(refresh)="search({ page: 1 })">
      <template #toolbar(search)>
        <a-input
            is-search
            placeholder="Buscar..."
            @search="search({ search: $event, page: 1 })" />
      </template>
      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item :to="{ name: 'pensums.show', params: { id: item.id }}">
              Ver
            </a-dropdown-item>
            <a-dropdown-item :to="{ name: 'pensums.edit', params: { id: item.id } }" v-if="user.isAdmin">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="deletePensum(item.id)" v-if="user.isAdmin">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data: () => ({
    query: {
      limit: 10,
      with: 'specialization,semesters.enabledCourses',
      search: '',
      sort_by: 'name'
    },
    timeout: null
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.pensums.pagination,
      loading: state => state.pensums.loading
    }),
    columns() {
      return [
        { title: 'Nombre', key: 'name', display: true },
        { title: 'Descripción', key: 'description', display: true },
        { title: 'Especialización', key: 'specialization.title', display: true },
        { title: 'Créditos', key: 'credits', alignment: 'right', display: true },
        { title: 'Acciones', slot: 'actions', alignment: 'right', columnFilterable: false, display: !this.user.isStudent },
      ].filter($0 => $0.display)
    }
  },
  methods: {
    ...mapActions({
      fetchPensums: 'pensums/index',
      deletePensum: 'pensums/delete'
    }),
    search(query) {
      this.fetchPensums({ ...this.query, ...query })
    }
  },
  mounted() {
    this.search({ ...this.query, page: this.$route.query.page || 1 })
  },
  created() {
    this.query = {
      ...this.query,
      ...this.$route.query
    }
  }
}
</script>
